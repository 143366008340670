<template>
   <div  :class="[scjFlag?'scj-out':'','out']">
          <div class="header">
              <div class="logo Title">
                  
                  <div class="Chinese"><img
			src="../assets/img/return_index.png"
			class="return1"
			alt=""
			@click="$router.back()"
        	/>{{$t('main.htr')}}</div>
              </div>
              <div class="right-icon">
                  <img src="../assets/img/logo-icon.png" alt="">
                  <div class="cc-wc">
                      {{$t('main.titleCenter')}}
                  </div>
              </div>
          </div>
        <div class="body">
            <div class="item_text">
                <div>{{($route.query.is_cul ||query.is_cul)?'文创品名称': $t('main.trname')}}：</div>
                <div style="color:#0056d7">{{name}}</div>
            </div>
            <div class="item_text">
                <div>{{($route.query.is_cul ||query.is_cul) ?'长城文创品编码：':$t('main.gwtc')}}</div>
                <div style="color:#fe0000">{{serial_number}}</div>
            </div>
            <div class="item_text">
                <div>{{$t('main.numoftran')}}</div>
                <div>{{list.length}} {{ $i18n.locale == 'en' ?'time':'次' }}</div>
            </div>
            <div class="item_text" v-if="showlist.length">
                <div>{{$t('main.nowholder')}}</div>
                <div class="flex_tt"><img class="avatar_img" :src="showlist[0].avatar" alt="">
                    <div class="name" style="color:#b5962c;width:1.7rem">{{showlist[0].name}}</div></div>
            </div>
            <div class="item_text1" v-show="(!$route.query.is_cul) && (!query.is_cul)" >
                <div>{{$t('main.trfatotwmb')}}</div>
                
            </div>
            <div class="item_text2" v-show="(!$route.query.is_cul) && (!query.is_cul)">
                <div></div>
                <div><img class="avatar_img" :src="user.pic+'?width=22'" alt=""> <span style="color:#0056d7;margin-right:0.03rem">{{user.name }} </span>
                <span> {{$tc('main.caAuthTran',scjFlag?1:0)}}</span></div>
            </div>
            <div class="list_price">
                <div class="cw_title">{{$t('main.bctranre')}}</div>
                <div :class="['list_item',index == list.length-1?'li_bg':'']" v-for="(item , index ) in list" :key="index" >
                    <div>
                        <img src="../assets/img/qkl.png" alt="">
                        <div>TxHash：{{item.tx_hash}}</div>
                    </div>
                    <div>
                        <div class="flex">
                            <img src="../assets/img/cyz.png" alt="">
                            <div>{{$t('main.holder')}}:</div>
                            <img class="avatar_img" :src="item.avatar" alt="">
                            <div class="name" style="color:#b5962c">{{item.name}}</div>
                        </div>
                        <div style="color:#cb1616" v-show="(!$route.query.is_cul) && (!query.is_cul) " >￥{{format_with_regex(item.price)}}</div>
                    </div>
                    <div >
                        <div class="flex">
                            <img src="../assets/img/zyz.png" alt="">
                            <div>{{  item.parent?$t('main.holder')+':': linkArtist.length? '联名作者：' :scjFlag?$t('main.collector')+':':$t('main.author')+':'}}</div>
                            <img class="avatar_img" :src="item.parent?item.parent.avatar:user.pic+'?width=22'" alt="">
                            <div  class="name" :style="item.parent?'color:#343434':'color:#0056d7'">{{item.parent?item.parent.name:user.name}}</div>
                            
                        </div>
                        
                        <div style="color:#707070">{{item.created_at.split(' ')[0]}}</div>
                    </div>
                    <div v-if="!item.parent" style="display:block">
                        <div v-for="(item1 , index1) in linkArtist" :key="index1"  >
                            <div class="flex">
                                <img src="../assets/img/zyz.png" alt="">
                                <div>联名作者：</div>
                                <img class="avatar_img" :src="item1.link_artist.pic+'?width=22'" alt="">
                                <div  class="name" >{{item1.link_artist.name}}</div>
                                
                            </div>
                            
                            <div style="color:#707070">{{item1.status == 0?'未同意':item1.status == 1?'已同意':'不同意'}}</div>    
                        </div>
                    </div>
                    

                </div>
                <div class="no_data" v-if="!list.length">
                    <img src="../assets/img/box_index.png" alt="">
                    <div>{{$t('main.nodata')}}</div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data(){
        return{
            name:'',
            serial_number:'',
            number:0,
            list:[],
            showlist:[],
            uuid:'',
            user:{
                name:'',
                pic:''
            },
            scjFlag:false,
            linkArtist:[],
            query:{
                is_cul:false
            }
        }
    },
     mounted(){
        let query = {}
        if(this.$route.query.__params){
            let json = JSON.parse(this.$base64.decode(this.$route.query.__params))
            
            query = json
            this.$route.params.id = query.id
            this.query = query
        }
        if(this.$route.query.is_cul || query.is_cul ){
            this.axios.get(`/collectionder/transfer_log?goods_derivative_id=${this.$route.params.id}`).then((res)=>{
                
                this.name = res.data.data.goodsDerivative.name
                this.serial_number = res.data.data.goodsDerivative.serial_number
                this.list = res.data.data.data.slice(0,res.data.data.data.length - 1) || []
                this.showlist = res.data.data.data || []
                this.user.name  = res.data.data.goodsDerivative.artist.name
                this.user.pic = res.data.data.goodsDerivative.artist.pic
                // this.price = res.data.data.data.length? res.data.data.data[0].price : res.data.data.goods.price
                // this.list = res.data.data.data
                
                if(res.data.data.goodsDerivative.artist.type==2){
                    this.scjFlag=true
                }
            })
            return 
        }
         this.axios.get(`/collection/transfer_log?goods_id=${this.$route.params.id}`).then((res)=>{
            
            this.name = res.data.data.goods.name
            this.serial_number = res.data.data.goods.serial_number
            this.list = res.data.data.data || []
            this.showlist = res.data.data.data || []
            this.user.name  = res.data.data.goods.artist.name
            this.user.pic = res.data.data.goods.artist.pic
           this.linkArtist = res.data.data.goods.linkArtist || []
            // this.price = res.data.data.data.length? res.data.data.data[0].price : res.data.data.goods.price
            // this.list = res.data.data.data
            if(res.data.data.goods.artist.type==2){
                this.scjFlag=true
            }
        })
    },
    methods:{
        format_with_regex(number) {
        return !(number + '').includes('.')
            ? // 就是说1-3位后面一定要匹配3位
            (number + '').replace(/\d{1,3}(?=(\d{3})+$)/g, (match) => {
                return match + ',';
            })
            : (number + '').replace(/\d{1,3}(?=(\d{3})+(\.))/g, (match) => {
                return match + ',';
            });
        },
    }
}
</script>

<style scoped>
.out {
		padding: 0.15rem;
		min-height: 100vh;
		overflow: auto;
		box-sizing: border-box;
		box-sizing: border-box;
		background: url(../assets/img/index_bg.png) no-repeat center;
		background-size: cover;
		background-attachment: fixed;
        color: #343434;
	}
    .out.scj-out {
		padding: 0.15rem;
		min-height: 100vh;
		overflow: auto;
		box-sizing: border-box;
		box-sizing: border-box;
		background: url(../assets/img/scj-in-bg.png) no-repeat center;
		background-size: cover;
		background-attachment: fixed;
        color: #343434;
	}

	.header {
		display: flex;
		justify-content: space-between;
		/* line-height: 0; */
		margin-bottom: 0;
		box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.3);
		margin-left: -0.15rem;
		margin-right: -0.15rem;
		margin-top:-0.14rem;
		margin-bottom: 0.15rem;
		padding: 0 0.15rem;
		align-items: center;
		background: #fff;
        height: 0.5rem;
        box-sizing: border-box;
		
	}
	
	.Title {
		font-size: 0.18rem;
		position: relative;
	}

	.English {
		color: #1b6a21;
		opacity: 0.3;
		position: absolute;
		top: 0.06rem;
		left: 0rem;
		text-align: left;
        font-size: 0.15rem;
        white-space: nowrap;
	}

	.Chinese {
		color: #1b6a21;
		
		left: 0;
		position: relative;
		text-align: left;
	}

	.Chinese .count {
		margin-left: 0.5rem;
	}

	.cc-wc {
		font-size: 0.1rem;
		color: #e9630a;
	}
	
	.right-icon {
		/* width: 1rem; */
		display: flex;
		    flex-direction: column;
		    align-items: flex-end;
	}

	.right-icon img {
		display: block;
		width: 100%;
		height: 100%;
		    width: 0.65rem;
	}
    .body{
        width: 100%;
        box-sizing: border-box;
        padding: 0.15rem;
    }
    .item_text{
        margin-bottom: 0.1rem;
        font-size: 0.15rem;
        display: flex;
        align-items: center;
        color: #343434;
    }
    .item_text1{
        margin-bottom: 0.1rem;
        font-size: 0.15rem;
        display: flex;
        align-items: center;
        color: #343434;
    }
    .item_text2{
        margin-bottom: 0.1rem;
        font-size: 0.15rem;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #343434;
    }
    .item_text >div:nth-child(1){
        width: 1.1rem;
    }
    .list_price{
        margin-top: 0.2rem;
        display: flex;
        flex-direction: column;
        align-items: center;
        
    }
    .cw_title{
    position: relative;
    margin-bottom: 0.2rem;
    
}
.cw_title::before{
    content: '';
    display: block; position: absolute;
    left: -0.3rem;
    top: 0.08rem;
    background: url(../assets/img/bjgw.png) no-repeat;
    background-size: cover;
    width: 0.17rem;
    height: 0.13rem;
}
.cw_title::after{
    content: '';
    display: block; position: absolute;
    right: -0.3rem;
    top: 0.08rem;
    background: url(../assets/img/bjgw.png) no-repeat;
    background-size: cover;
    width: 0.17rem;
    height: 0.13rem;
    transform: rotateY(180deg);
}
.list_item{
    width: 100%;
    box-sizing: border-box;
    padding: 0.12rem 0.16rem;
    background: #fff;
    margin-bottom: 0.1rem;
}
.list_item img{
    width: 0.14rem;
    object-fit: contain;
    display: block;
    margin-right: 0.08rem;
}
.list_item > div{
    display: flex;
    align-items: center;
}
.list_item > div:nth-child(1){
    margin-bottom: 0.145rem;
    font-size: 0.1rem;
}
.list_item > div:nth-child(2){
    margin-bottom: 0.175rem;

}
.list_item > div:nth-child(2) , .list_item > div:nth-child(3){
    justify-content: space-between;
    font-size: 0.13rem;
}
.list_item > div:nth-child(4) > div{
    display: flex;
    justify-content: space-between;
    font-size: 0.13rem;
    margin-top: 0.1rem;
}
.flex{
    display: flex;
    align-items: center;
}
.avatar_img{
    width: 0.225rem !important;
    height: 0.225rem;
    margin: 0 0.05rem !important;
    vertical-align: bottom;
    object-fit: contain;
}
.name{
    width: 0.8rem;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.no_data{
    width: 3.45rem;
    height: 3.13rem;
    background: #fff;
    border-radius: 0.05rem;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}
.no_data img{
    width: 2.11rem;
    object-fit: contain;
}
.no_data div{
    margin-top: 0.4rem;
    color:#848484;
}
.li_bg{
    background: url(../assets/img/record_bg.png) no-repeat;
    background-size: 100% 100%;
}
.flex_tt{
    display: flex;
    align-items: center;
}
.flex_tt .avatar_img{
    margin: 0 !important;
    margin-right: 0.05rem !important;
}
</style>